import './App.css';
import MainBlock from './components/MainBlock';
import Navbar from './components/Navbar';

function App() {
	return (
		<div className='App'>
			<Navbar />
			<MainBlock />
		</div>
	);
}

export default App;
