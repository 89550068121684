import axios from "axios";
import {getToken} from '../helpers/tokenHelper';

export const $api = axios.create({
    baseURL: `${process.env.REACT_APP_BASEURL}`,
});

export const config = () => {
    return  {
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Expose-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        "Authorization": getToken(),
    }
}

$api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.code == 'BAD_REQUEST' || error.code == 'FORBIDDEN'
        || error.response.status == 401 || error.response.status == 403) {
        localStorage.removeItem("tokenValue");
        localStorage.removeItem("tokenType");
        window.location.replace("/panel/login");
        return;
    }
    return Promise.reject(error);
});