const Instruments = () => {
	return (
		<div className='sm:block flex flex-col justify-center items-center mt-5'>
			<div className='flex'>
				<div className='rounded-3xl bg-white text-black py-2 px-4 mr-2 mb-2'>
					Javascript
				</div>
				<div className='rounded-3xl bg-white text-black py-2 px-4 mr-2 mb-2'>
					C++
				</div>
				<div className='rounded-3xl bg-white text-black py-2 px-4 mb-2'>
					Java и JVM
				</div>
			</div>
			<div className='flex'>
				<div className='rounded-3xl bg-white text-black py-2 px-2 sm:px-4 mr-2 mb-2'>
					Python и Django
				</div>
				<div className='rounded-3xl bg-white text-black py-2 px-4 mr-2 mb-2'>
					Go
				</div>
				<div className='rounded-3xl bg-white text-black py-2 px-4 mb-2'>
					Ruby и Rails
				</div>
			</div>
			<div className='flex'>
				<div className='rounded-3xl bg-white text-black py-2 px-4 mr-2 mb-2'>
					SQL
				</div>
				<div className='rounded-3xl bg-white text-black py-2 px-4 mr-2 mb-2'>
					Kotlin
				</div>
				<div className='rounded-3xl bg-white text-black py-2 px-4 mb-2'>
					Все инструменты
				</div>
			</div>
		</div>
	);
};

export default Instruments;
