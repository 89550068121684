import Footer from './Footer';
import Card from './main-block/Card';
import Instruments from './main-block/Instruments';
import More from './main-block/More';
import News from './main-block/News';

const cardsInfo = [
	{
		title: 'Для бизнеса',
		body: 'Полный набор инструментов для бизнеса',
		src: './pngs/business.png',
	},
	{
		title: 'Для команд',
		body: 'Широкий выбор инструментов и интеграция для любых команд',
		src: './pngs/team.png',
	},
	{
		title: 'Для обучения',
		body: 'Возможности для студентов и преподавателей',
		src: './pngs/learning.png',
	},
	{
		title: 'Kotlin/Java',
		body: 'Современный язык для мультплатформенной разработки',
		src: './pngs/kotlin.png',
	},
];

const MainBlock = () => {
	return (
		<div className='relative isolate px-6 lg:px-8'>
			<div
				className='absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80'
				aria-hidden='true'
			>
				<div
					className='relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]'
					style={{
						clipPath:
							'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
					}}
				/>
			</div>
			<div className='mx-auto max-w-7xl py-6 sm:py-48 lg:py-12'>
				<div className='sm:mb-8 sm:flex'>
					<h1 className='text-2xl font-bold tracking-tight text-white sm:text-6xl text-left'>
						Программное обеспечение для вас и вашего бизнеса
					</h1>
				</div>
				<div className='text-left'>
					<div className='relative py-1 text-sm leading-6 text-white text-[19px]'>
						Имеются все нужные инструменты для разработчиков и команды.
					</div>
				</div>
			</div>
			<div
				className='absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]'
				aria-hidden='true'
			>
				<div
					className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]'
					style={{
						clipPath:
							'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
					}}
				/>
			</div>
			<div className='cards sm:flex max-w-7xl mx-auto grid grid-cols-2 gap-4'>
				{cardsInfo.map(item => {
					return <Card body={item.body} src={item.src} title={item.title} />;
				})}
			</div>
			<div className='sm:flex max-w-7xl mx-auto mt-[30px] text-white'>
				<div className='mb-[16px] sm:m-[16px] relative'>
					<img className='w-[629px] h-[504px]' src={'./pngs/januariAI.png'} />
					<div className='absolute top-0 text-left pt-[24px] pl-[28px]'>
						<div>
							<img src='./pngs/spirale.png' />
						</div>
						<div className='text-[42px] mb-[19px] mt-[15px]'>January AI</div>
						<div className='text-[29px] mb-[12px]'>
							Новые возможности для ваших инструментов
						</div>
						<div className='text-[28px]'>Новая свобода для вас</div>
					</div>
					<div className='absolute bottom-0 ml-[28px] mb-[24px] text-black rounded bg-white py-[8px] px-[24px] cursor-pointer'>
						Попробовать
					</div>
				</div>
				<div>
					<div className='mb-[16px] sm:m-[16px] relative'>
						<img
							className='w-full sm:w-[307px] h-[256px]'
							src={'./pngs/code.png'}
						/>
						<div className='absolute top-0 text-left pt-[18px] pl-[16px]'>
							<div className='text-[20px] mb-[19px] mt-[15px]'>
								Воплощайте любые идеи в коде
							</div>
							<div className='text-[16px] mt-[14px]'>
								Невероятно удобные инструменты для бизнеса
							</div>
						</div>
						<div className='absolute bottom-0 text-[16px] ml-[28px] mb-[24px] sm:ml-[14px] sm:mb-[6px] rounded border border-gray-600 py-[4px] px-[10px] cursor-pointer text-white'>
							Выбрать
						</div>
					</div>
					<div className='mb-[16px] sm:m-[16px] relative'>
						<img
							className='w-full sm:w-[307px] h-[232px]'
							src={'./pngs/develop.png'}
						/>
						<div className='absolute top-0 text-left pt-[18px] pl-[16px]'>
							<div className='text-[20px] mb-[19px] mt-[15px]'>
								Состояние экосистемы разработки в 2024 году
							</div>
						</div>
						<div className='absolute bottom-0 text-[16px] ml-[28px] mb-[24px] sm:ml-[14px] sm:mb-[6px] rounded border border-gray-600 py-[4px] px-[10px] cursor-pointer text-white'>
							Подробнее
						</div>
					</div>
				</div>
			</div>
			<div className='max-w-7xl mx-auto mt-[30px] text-white text-left mt-[32px] sm:mt-[80px]'>
				<div className='text-[26px] sm:text-[30px] mb-[8px] sm:mb-[18px]'>
					Независимо от сегмента вашего бизнеса, наши продукты помогут вам легко
					и с удовольствием
				</div>
				<div>
					<div className='text-[22px] sm:text-[29px]'>
						<span className='flex items-end'>
							<img
								className='w-[24px] h-[24px] mr-[8px] mt-[12px]'
								src='./pngs/liCode.png'
							/>
							выполнять написание кода
						</span>
					</div>
					<div className='text-[22px] sm:text-[29px]'>
						<span className='flex items-end'>
							<img
								className='w-[24px] h-[24px] mr-[8px] mt-[12px]'
								src='./pngs/liArch.png'
							/>
							архитектура решения
						</span>
					</div>
					<div className='text-[22px] sm:text-[29px]'>
						<span className='flex items-end'>
							<img
								className='w-[24px] h-[24px] mr-[8px] mt-[12px]'
								src='./pngs/liSwap.png'
							/>
							сотрудничать с коллегами
						</span>
					</div>
				</div>
				<div className='text-[24px] sm:text-[29px] mt-[32px] sm:mt-[54px]'>
					С какими бы платформами и языками вы ни работали, у Rell Tech найдется
					подходящий инструмент
				</div>
				<Instruments />
				<More />
				<News />
				<Footer />
			</div>
		</div>
	);
};

export default MainBlock;
