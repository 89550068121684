import { Dialog, Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ');
}

const Navbar = () => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

	return (
		<header className='z-10 relative'>
			<nav
				className='mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8'
				aria-label='Global'
			>
				<div className='flex lg:flex-1'>
					<a href='#' className='-m-1.5 p-1.5'>
						<span className='sr-only'>RellTech</span>
						<img className='h-8 w-auto' src='/logo.svg' alt='' />
					</a>
				</div>
				<div className='flex lg:hidden'>
					<button
						type='button'
						className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
						onClick={() => setMobileMenuOpen(true)}
					>
						<span className='sr-only'>Menu</span>
						<Bars3Icon className='h-6 w-6' aria-hidden='true' />
					</button>
				</div>
				<Popover.Group className='hidden lg:flex lg:gap-x-8'>
					<a href='#' className='text-sm leading-6 text-white'>
						Проекты
					</a>

					<a href='#' className='text-sm leading-6 text-white'>
						Услуги
					</a>
					<a href='#' className='text-sm leading-6 text-white'>
						Образование
					</a>
					<a href='#' className='text-sm leading-6 text-white'>
						Решения
					</a>
					<a href='#' className='text-sm leading-6 text-white'>
						Поддержка
					</a>
				</Popover.Group>
				<div className='hidden lg:flex lg:flex-1 lg:justify-end '>
					<Popover.Group className='hidden lg:flex lg:gap-x-8 '>
						<a
							href='#'
							className='text-sm font-semibold leading-6 text-gray-900'
						>
							<img className='h-4' src='./svgs/search.svg' />
						</a>
						<a
							href='tel:+74999900911'
							className='text-sm font-semibold leading-6 text-gray-900'
							style={{color: "white"}}
						>
							<PhoneIcon className='h-4' style={{color: "white"}}/>
						</a>
						<a
							href='https://id.rell.ru/?continue=https://rell.ru'
							className='text-sm font-semibold leading-6 text-gray-900'
						>
							<img className='h-4' src='./svgs/user.svg' />
						</a>
						<a
							href='#'
							className='text-sm font-semibold leading-6 text-gray-900'
						>
							<img className='h-4' src='./svgs/translate.svg' />
						</a>

					</Popover.Group>
				</div>
			</nav>
			<Transition appear show={mobileMenuOpen}>
				<Dialog
					as='div'
					className='lg:hidden bg-#27282c'
					onClose={setMobileMenuOpen}
				>
					<div className='fixed inset-0 z-10' />
					<Transition.Child
						enter='ease-out duration-300'
						enterFrom='opacity-0 transform-[scale(95%)]'
						enterTo='opacity-100 transform-[scale(100%)]'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 transform-[scale(100%)]'
						leaveTo='opacity-0 transform-[scale(95%)]'
					>
						<Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-[#27282c] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
							<div className='flex items-center justify-between'>
								<div className='flex lg:flex-1'>
									<a href='#' className='-m-1.5 p-1.5'>
										<span className='sr-only'>RellTech</span>
										<img className='h-8 w-auto' src='/logo.svg' alt='' />
									</a>
								</div>
								<button
									type='button'
									className='-m-2.5 rounded-md p-2.5 text-gray-700'
									onClick={() => setMobileMenuOpen(false)}
								>
									<span className='sr-only'>Close menu</span>
									<XMarkIcon className='h-6 w-6' aria-hidden='true' />
								</button>
							</div>
							<div className='mt-6 flow-root'>
								<div className='-my-6 divide-y divide-gray-500/10'>
									<div className='space-y-2 py-6'>
										<a
											href='#'
											className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white'
										>
											Проекты
										</a>
										<a
											href='#'
											className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white'
										>
											Услуги
										</a>
										<a
											href='#'
											className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white'
										>
											Образование
										</a>
										<a
											href='#'
											className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white'
										>
											Решения
										</a>
										<a
											href='#'
											className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white'
										>
											Поддержка
										</a>
										<a
											href='tel:+74999900911'
											className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white'
										>
											Связаться
										</a>
									</div>

									<div className='py-6'>
										<a
											href='https://id.rell.ru/?continue=https://rell.ru'
											className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white'
										>
											Войти
										</a>
									</div>
								</div>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</Dialog>
			</Transition>
		</header>
	);
};

export default Navbar;
