import {useEffect, useState} from "react";
import {NewsDto, PageNewsDto} from "../../types/news/news.types";
import {getNews} from "../../api/controllers/news-controller";
import {formatDateTime} from "../../helpers/dateFormatter";

const News = () => {

	const [news, setNews] = useState<NewsDto[]>([]);

	useEffect(() => {
		getNews({page: 0, size: 10, sort: 'asc'}).then((response) => {
			setNews(response.data.content);
		}).catch(err => {
			console.log(err);
		})
	}, [])

	return (
		<div className='rounded-[8px] p-[32px] sm:px-[0] gradient-block mt-[40px]'>
			<h1 className='text-white sm:px-[124px] sm:pt-[100px] text-[32px] sm:text-[35px] mb-[28px] sm:mb-[0]'>
				Новости и события
			</h1>
			<div className='flex flex-col gap-[32px] items-center sm:py-[97px] sm:px-[124px] sm:w-[100%] sm:flex-row'>

				{
					news.length > 0 ? news.slice(0, 3).map((item) => {
						return (
							<div className='rounded justify-center items-center bg-white text-black px-[25px] py-[25px] sm:h-[390px] w-[290px] overflow-auto sm:mr-[40px]'>
								<p className='mb-[14px]'>{formatDateTime(item.publishedDate)}, {item.author}</p>
								<p className='mb-[12px] ' >{item.title}</p>
								<p className='mb-[10px]'>
									{item.content.substring(0, 180)}
								</p>
								<p>
									<a onClick={() => console.log("not impl")}>
										Подробнее...
									</a>
								</p>
							</div>)

					}) : (<div>Новостей пока что нет</div>)
				}
			</div>
			{
				news.length > 3 ? (<div style={{marginRight: 20, textAlign: 'right'}}><a href='#'>{'Все новости >>'} </a></div>) : (<></>)
			}
		</div>
	);
};

export default News;
