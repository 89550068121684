
export function formatDateTime(date: Date | string | number | number[]) {

    const checkDigits = (value: number): string => {
        return value < 10 ? `0${value}`: value + '';
    }


    if (date instanceof Array) { // timestamp backend serialize as number[]
        const hours = checkDigits(date[3])
        const minutes = checkDigits(date[4])
        const seconds = checkDigits(date[5])
        return `${date[0]}/${date[1]}/${date[2]} ${hours}:${minutes}:${seconds}`
    } else {
        const formattedDate = new Date(date);

        const year = formattedDate.getFullYear();
        const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
        const day = String(formattedDate.getDate()).padStart(2, '0');

        const hours = String(formattedDate.getHours()).padStart(2, '0');
        const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
        return `${year}.${month}.${day} ${hours}:${minutes}`;
    }

}