const Footer = () => {
	return (
		<>
			<div className='text-white flex justify-center mt-[32px] sm:mt-[66px]'>
				<div className='flex flex-col gap-4 sm:flex-row'>
					<div>
						<p>Продукты</p>
						<p className='opacity-30'>January AI</p>
						<p className='opacity-30'>Инструменты для совместной работы</p>
						<p className='opacity-30'>Плагины</p>
						<p className='opacity-30'>Образование</p>
						<p className='opacity-30'>Языки</p>
						<p className='opacity-30'>Все продукты</p>
					</div>
					<div>
						<p>Решения</p>
						<p className='opacity-30'>Работы с данными</p>
						<p className='opacity-30'>DevOps</p>
						<p className='opacity-30'>Разработка игр</p>
						<p className='opacity-30'>Разработка ПО</p>
						<p className='opacity-30'>Инструменты для бизнеса</p>
						<p className='opacity-30'>Контроль качества</p>
					</div>
					<div>
						<p>Инициативы</p>
						<p className='opacity-30'>Kotlin</p>
						<p className='opacity-30'>Java</p>
						<p className='opacity-30'>Scala</p>
						<p className='opacity-30'>Проекты с открытым исходным кодом</p>
					</div>
					<div>
						<p>Сообщество</p>
						<p className='opacity-30'>Лицензии для образования</p>
						<p className='opacity-30'>
							Поддержка проектов с открытым исходным кодом
						</p>
						<p className='opacity-30'>IT-сообщества</p>
						<p className='opacity-30'>Поддержка мероприятий</p>
						<p className='opacity-30'>Поддержка экспертов по технологиям</p>
					</div>
					<div>
						<p>Ресурсы</p>
						<p className='opacity-30'>Отдел продаж</p>
						<p className='opacity-30'>Поддержка продуктов</p>
						<p className='opacity-30'>Вопросы по лицензированию</p>
						<p className='opacity-30'>Документация</p>
						<p className='opacity-30'>Ранний доступ</p>
						<p className='opacity-30'>События и вебинары</p>
						<p className='opacity-30'>Рассылки</p>
						<p className='opacity-30'>Исследования</p>
						<p className='opacity-30'>Блог</p>
					</div>
					<div>
						<p>Компания</p>
						<p className='opacity-30'>О компании</p>
						<p className='opacity-30'>Контакты</p>
						<p className='opacity-30'>Карьера</p>
						<p className='opacity-30'>Новости</p>
						<p className='opacity-30'>Клиенты и награды</p>
						<p className='opacity-30'>Наши обязательства</p>
						<p className='opacity-30'>Партнеры и реселлеры</p>
					</div>
				</div>
			</div>
			<hr className='bg-white opacity-20 mt-[42px] sm:mt-[110px] mb-[50px]' />
			<div className='sm:flex text-white'>
				<p className='mr-[16px]'>Защита персональных данных и безопасность</p>
				<p className='mr-[16px]'>Правила использования</p>
				<p className='mr-[16px]'>Товарные знаки</p>
				<p>Юридическая информация</p>
			</div>
			<div className='text-white mb-[32px]'>
				<p>Оригинальные инструменты</p>
			</div>
			<div className='text-white opacity-20 text-center'>
				<p>Copyright © 2024 Rell Tech</p>
			</div>
		</>
	);
};

export default Footer;
