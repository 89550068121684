import { FC } from 'react';

type CardType = {
	src: string;
	title: string;
	body: string;
};

const Card: FC<CardType> = ({ body, src, title }) => {
	return (
		<div className='sm:w-[200px] sm:mr-[130px]'>
			<div className='flex'>
				<img className='w-[19px] h-[19px] mt-1 mb-1 mr-1' src={src}></img>
				<span className='text-white'>{title}</span>
			</div>
			<div className='text-white opacity-30 text-left pt-[15px]'>{body}</div>
		</div>
	);
};

export default Card;
